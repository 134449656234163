@import 'bulma/sass/utilities/initial-variables.sass';
@import 'bulma/sass/utilities/functions.sass';

$size-1: 4rem;
@import 'bulma/sass/utilities/derived-variables.sass';

$info: $white;
$info-invert: $white;

$addColors: (
  "info":($info, $info-invert),
);

$colors: map-merge($colors, $addColors);

$family-primary: "Glacial Indifference", sans-serif;
$family-secondary: "Playfair Display", serif;

$family-sans-serif: "Heebo", "Roboto", sans-serif;
$section-padding: 3rem 2.5rem;
$navbar-breakpoint: $desktop;

@import 'bulma/bulma.sass';

.is-fixed {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  z-index: 1000;
}

#root {
  // margin-top: 6rem;
  background-color: $white-bis;
  // @media screen and (max-width: 571px) {
  //   margin-top: 5.25rem;
  // }
}

.upper-layer {
  z-index: 100;
}

.is-relative {
  position: relative;
}

.fade-in {
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
}

.fadeOut {
  opacity: 0;
  transition: opacity 15s, margin-top 0s 0s;
}

.fadeIn {
  opacity: 1;
  transition: opacity 8s, margin-top 0s 0s;
}

.faded-background {
  background-color: rgba(0, 0, 0, 0.7) !important;
  border-color: transparent !important;
  width: fit-content !important;
}


.bumper-top {
  margin-top: 2rem;
}

.bumper-top.double {
  margin-top: 4rem;
}

.bumper-top-small {
  margin-top: 0.5rem; 
}

.subtle-divider {
  width: 48px;
  height: 2px;
  background-color: hsla(0, 0%, 100%, 0.4);
  color: rgba(33, 33, 33, 0.17);
}

.divider {
  width: 48px;
  height: 2px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: hsla(0, 0%, 100%, 0.4);
  color: rgba(33, 33, 33, 0.17);
}

.divider.black {
  width: 250px;
  background-color: #3636365c;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 4rem;
}

.navbar > .container {
  width: 90%;
}

.responsive-section {
  padding: 3rem 2.5rem; 
}

.small-section {
  padding: 1rem 1.5rem;
}

@media screen and (max-width: $tablet) {
  .reverse-column-order {
    display: flex;
    flex-direction: column-reverse;
  }
  .responsive-section {
    padding: 1rem 2rem; 
  }
}

.text-column {
  flex-direction: column;
}


@media screen and (max-width: $desktop) {
  .center-text {
    text-align: center;
  }
}

#root {
  // overflow-x: hidden;
  overflow: hidden;
}

.has-modal-off {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,0.9);
  z-index: 1000;
  pointer-events: none;
  opacity: 0;
  transition: 0.3s;
}

.has-modal-on {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,0.9);
  z-index: 1000;
  pointer-events: auto;
  opacity: 1;
  transition: 0.3s;
}

.has-modal-on-lower-opacity {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.233);
  z-index: 1000;
  pointer-events: auto;
  opacity: 1;
  transition: 0.3s;
}

.bio-pop-up {
  box-shadow: 0 0 30px rgba(0,0,0,0.2);
  box-sizing: border-box;
  padding: 6% 4% 3% 4%;
  max-width: 720px;
  font-size: 0;
  width: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1050;
  background-color: #FFF;
  border-color: #FFF;
  text-align: left;
  @media screen and (max-width: 735px) {
    width: 80%;
  }  
}

.test-helper-container {
    height: 58%;
    overflow-y: auto;
    overflow-x: hidden;
    box-sizing: border-box;
    max-width: 720px;
    min-width: 300px;
    font-size: 0;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1050;
    text-align: left;
}

.display-block {
  display: block !important;
}

.pop-up-on {
  display: inline-block;
}

.pop-up-off {
  display: none;
}

.bio-close {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
}

.bio-image {
  float: right;
  width: 200px;
  margin-top: -135px;
  margin-right: -20px;
  @media screen and (max-width: 735px) {
    width: 135px;
    margin-top: -69px;
    margin-right: 11px;
  }  
}

.bio-body {
  padding-top: 34px;
  @media screen and (max-width: 450px) {
    padding-top: 10px;
  }
}

.rounded-icon {
  border-radius: 142px;
}

.shadow-lift {
  animation: flyintoright .4s backwards;
  background: #fff;
  box-shadow: 0 6px 11px rgba(0, 0, 0, 0.19);
  display: inline-block;
  position: relative;
  transition: all .2s ease-in-out;
}

.shadow-lift:hover {
  box-shadow: 0 10px 16px rgba(0, 0, 0, 0.13), 0 6px 6px rgba(0, 0, 0, 0.19); 
  transform: scale(1.08);
  -webkit-transform: scale(1.08);
}

.news-lift {
  animation: flyintoright .4s backwards;
  background: #fff;
  box-shadow: 0 6px 11px rgba(0, 0, 0, 0.19);
  position: relative;
  transition: all .3s ease-in-out;
}

.news-lift:hover {
  box-shadow: 0 10px 16px rgba(0, 0, 0, 0.13), 0 6px 6px rgba(0, 0, 0, 0.19);
  transform: scale(1.02);
  -webkit-transform: scale(1.02);
}

.text-lift {
  animation: flyintoright .4s backwards;
  position: relative;
  transition: all .3s ease-in-out;
}

.text-lift:hover {
  transform: scale(1.02);
  -webkit-transform: scale(1.02);
}

.clickable {
  cursor: pointer;
}

#modal-bio {
  animation: flyintoright .4s backwards;
  transition: all .2s ease-in-out;
}

.bio-image-sizing {
  max-width: 225px;
  min-width: 128px;
}

.bio-social {
  font-size: 0;
  padding-top: 20px;
}

.social-img {
  width:25px;
}

.social-img-phone {
  width:20px;
}

.language-img {
  width: 40px;
  margin-left: 1rem;
  @media screen and (min-width:700px) {
  }
}

.right-angle-arrow {
  width: 14px;
  // margin-left: 1rem;
  margin-left: auto;
  margin-right: 1rem;
  @media screen and (min-width:700px) {
  }
}

.post-row-spacing {
  padding-right: 1rem;
  padding-left: 1rem;
}

.hidden {
  display: none;
}

.invisible {
  visibility: hidden;
}
 
.read-more {
  visibility: hidden;
  height: 0;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}

.is-active {
  visibility: visible;
  opacity: 1;
  height:auto;
}

.is-hidden {
  visibility: hidden;
  display: none;
  height: 0;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}


.justify-text {
  text-align: justify;
}

.text-align-center {
  text-align: center;
}

.card-content .fix{
  @media screen and (min-width: 768px) and (max-width: 980px) {
    margin: 0rem !important;
  }
}

.pos-relative {
  position: relative;
}

.tile-button-position {
  @media screen and (min-width:769px) {
    position: absolute;
  }
  bottom: 1rem; 
}

.add-padding-bottom {
  padding-bottom: 1rem; 
}

#services-columns {
  @media screen and (min-width: 768px) {
    height: 650px; 
    // height: auto;
  }
}


// IMPORT FONT AWESOME
// @import url("https://use.fontawesome.com/releases/v5.10.1/css/all.css");
@import url('https://fonts.googleapis.com/css?family=Heebo&display=swap');
// @import url('https://fonts.googleapis.com/css?family=Playfair+Display:400,700&display=swap');